import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { mq } from '../styles'
import { LINKS } from '../constants'
import { Link } from 'gatsby'

const linkCss = css`
  width: 50%;
  max-width: 40rem;
  margin-left: 01em;
  display: none;
  flex-direction: row;
  align-items: center;
  li {
    a {
      font-size: 1rem;
      transition: color 0.2s;
      &:hover {
        color: var(--accent);
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        right: -0.5em;
        display: block;
        opacity: 0;
        transition: opacity 250ms ease-in-out;
      }

      &.active {
        &::after {
          content: '';
          display: block;
          height: 4px;
          width: 4px;
          opacity: 1;
          background: var(--text-main);
        }
      }
    }

    /* hover state bust be on parent element! */
    &:hover::after {
      transition: color 120ms ease-in-out;
      color: var(--accent);
    }
  }
`
const sidebarLinkCss = css`
  width: 100%;
  height: 100%;
  max-height: 500px;
  padding: 1.5em;

  li {
    padding: 1.25rem 0;
    a {
      /* font-family: Raleway, serif; */
      /* font-size: 2.5rem;
      font-weight: var(--fw-light); */
      font: var(--font-sidebar-link);
      text-transform: capitalize;
      &.active {
        transition: color 120ms ease-in-out;
        color: var(--accent);
      }
    }
    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 66%;
      border-top: 0.5px solid rgba(0, 0, 0, 0.05);
    }
  }
`

const StyledNavLinks = styled('ul')<{ forSidebar: boolean }>`
  justify-content: space-between;

  li {
    position: relative;
    width: 100;

    a {
      text-transform: lowercase;
      text-decoration: none;
    }
  }

  ${props => (props.forSidebar ? sidebarLinkCss : linkCss)}

  @media (min-width: ${mq.desktop}px) {
    display: flex;
    flex-direction: row;
  }
`

interface NavLinksProps {
  forSidebar?: boolean
  closeMenu?: () => void
}

const NavLinks = ({ forSidebar = false, closeMenu }: NavLinksProps) => {
  const links = [
    { to: '/about', text: 'About' },
    { to: '/portfolio', text: 'Illustrations' },
    { to: '/animations', text: 'Animations' },
    { to: '/contact', text: 'Contact' },
  ]

  const handleLinkItemClick = () => {
    if (forSidebar && closeMenu) {
      closeMenu()
    }
  }
  // TODO: try using AniLink and see if it improves perf (Sidebar animation seems to be interfering with page transition)

  const societySixLink = (
    <a
      href={LINKS.SOCIETY_SIX}
      target="_blank"
      rel="noreferrer noopener"
      aria-label="Shop"
    >
      Shop
    </a>
  )

  return (
    <StyledNavLinks forSidebar={forSidebar}>
      {links.map(link => (
        <li key={link.text} onClick={handleLinkItemClick}>
          <Link to={link.to} activeClassName="active">
            {link.text}
          </Link>
        </li>
      ))}
      <li>{societySixLink}</li>
    </StyledNavLinks>
  )
}

NavLinks.propTypes = {
  sidebar: PropTypes.bool,
  closeMenu: PropTypes.func,
}

export default NavLinks
