exports.components = {
  "component---src-components-post-template-tsx": () => import("./../../../src/components/post-template.tsx" /* webpackChunkName: "component---src-components-post-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-animations-tsx": () => import("./../../../src/pages/animations.tsx" /* webpackChunkName: "component---src-pages-animations-tsx" */),
  "component---src-pages-comingsoon-tsx": () => import("./../../../src/pages/comingsoon.tsx" /* webpackChunkName: "component---src-pages-comingsoon-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-files-tsx": () => import("./../../../src/pages/my-files.tsx" /* webpackChunkName: "component---src-pages-my-files-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */)
}

